import React from 'react';

import { graphql } from 'gatsby';

import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const Edit = loadable(() => import('@screens/perfil/Edit'));

function Index(props) {
  return (
    <Layout {...props} contentSx={{ minHeight: '100%' }}>
      <Edit {...props} />
    </Layout>
  );
}

export const EditPerfilQuery = graphql`
  {
    allFile(filter: { relativePath: { glob: "register/*.png" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
  }
`;

export default Index;
